import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AnimatedArrow from '../components/animated-arrow'
import IconWifi from '../images/icon-wifi.inline.svg'
import IconTv from '../images/icon-tv.inline.svg'
import IconTerrace from '../images/icon-terrace.inline.svg'
import IconChair from '../images/icon-chair.inline.svg'
import Slider, { NextSlide, CurrentSlide, MobileNav } from '../components/slider'

import '../styles/pages/private-dining.scss'

const PrivateDiningPage = ({ data: graphqlData, location }) => {
  const {
    pageTitle,
    pageDescription,
    pageImage,
    sectionTitle,
    sectionDescription,
    featuredTitle,
    featuredTopTitle,
    featuredImage,
    featuredDescription,
    privateDiningSlider,
  } = graphqlData.datoCmsPrivateDiningPage

  console.log(privateDiningSlider)

  return (
    <Layout className="private-dining-page" pathname={location.pathname} alwaysBlack>
      <SEO title="Private events" />

      <section className="container">
        <h1 dangerouslySetInnerHTML={{ __html: pageTitle }} />

        <div className="main-section section">
          <div dangerouslySetInnerHTML={{ __html: pageDescription }} />
          <div className="icons-container">
            <div className="icon-item">
              <IconTv className="text-tuya-slate" />
              Wide Display TV
            </div>
            <div className="icon-item">
              <IconWifi className="text-tuya-slate" />
              WiFi Connection
            </div>
            <div className="icon-item">
              <IconChair className="text-tuya-slate" />
              170 people seated
            </div>
            <div className="icon-item">
              <IconTerrace className="text-tuya-slate" />
              Liked to the terrace
            </div>
          </div>
        </div>

        {/* <figure className="page-image"> */}
        <Slider carousel={privateDiningSlider} reversed>
          <NextSlide sliderId="first" />
          <CurrentSlide sliderId="first" />
          <MobileNav sliderId="first" mt={4} className="mb-16" />
        </Slider>
        {/* <img src={pageImage.sizes.src} srcSet={pageImage.sizes.srcSet} alt="Private dining" /> */}
        {/* </figure> */}

        <div className="second-section section">
          <h3>{sectionTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: sectionDescription }} />
        </div>

        <figure className="featured-image bottom-image">
          <img
            src={featuredImage.sizes.src}
            srcSet={featuredImage.sizes.srcSet}
            alt="Private dining"
          />
        </figure>

        <div className="featured bottom-text">
          <h3>{featuredTopTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: featuredDescription }} />
          <Link to="/contact">
            <span>{featuredTitle}</span>
            <AnimatedArrow className="h-3 text-tuya-dark" />
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsPrivateDiningPage {
      pageTitle
      pageDescription
      pageImage {
        sizes {
          src
          srcSet
        }
      }
      privateDiningSlider {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
      }
      sectionTitle
      sectionDescription
      featuredTitle
      featuredTopTitle
      featuredDescription
      featuredImage {
        sizes {
          src
          srcSet
        }
      }
    }
  }
`

export default PrivateDiningPage
